<template>
    <div class="text-box">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "TextBox"
}
</script>

<style scoped>
.text-box {
    padding: 2em;
    background-color: rgba(242, 232, 207, 0.8);
    border-top: 5px #a7c957 solid;
    border-bottom: 15px #3d5a80 solid;
}
</style>