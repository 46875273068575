<template>
    <div class="topic-box" :id="id">
        <div class="image-container" :style="{backgroundImage: 'url('+getImage+')'}">
            <div class="box">
                <slot></slot>
            </div>
        </div>
        <div v-if="!isLargeBorder" class="border"></div>
        <div v-if="isLargeBorder" class="large-border"></div>
    </div>
</template>

<script>
export default {
    name: "TopicContainer",
    props: {
        id: String,
        image: String,
        isLargeBorder: Boolean
    },
    computed: {
        getImage() {
            return require(`@/assets/${this.image}`);
        }
    }
}
</script>

<style scoped>
.topic-box .box {
    width: 38%;
    min-height: 100vh;
    padding-top: 2em;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
}

.image-container {
    margin-left: 4em;
}

.border {
    border-top: 10px #a7c957 solid;
    border-bottom: 30px #3d5a80 solid;
}

.large-border {
    border-top: 25px #a7c957 solid;
    border-bottom: 70px #3d5a80 solid;
}

@media screen and (max-width: 1600px) {
    .topic-box .box {
        width: 48%;
    }
}

@media screen and (max-width: 1300px) {
    .topic-box .box {
        width: 65%;
        padding-left: 2em;
    }

    .image-container {
        margin-left: 0;
        background-position: 45% 2%;
    }
}

@media screen and (max-width: 1000px) {
    .topic-box .box {
        width: 100%;
        padding: 1em 2em 2em 2em;
    }
}

@media screen and (max-width: 800px) {
    .topic-box .box {
        padding: 1em 3em 2em 3em;
    }
}

@media screen and (max-width: 480px) {
    .topic-box .box {
        flex-direction: column;
        padding: 1em 1em 2em 1em;
    }
}
</style>